<template>
<div>
  <myheader></myheader>


	<div class="city">
	<div class="login">
        <div class="form">
          <div class="title flex-between">
            <h3>完善微信扫码信息</h3>
            <!-- <img src="" alt="" /> -->
          </div>
          <p class="tip">请填写下列信息</p>
          <div class="inp">
            <input
              type="text"
              placeholder="请输入用户名称"
              v-model="form.account"/>
          </div>
          
          <div class="sex">
            <div class="flex-around" @click="form.sex =1">
              <img src="../assets/public/check1.png" v-if="form.sex == 1" />
              <img src="../assets/public/check2.png" v-if="form.sex == 0" />
              <p>男</p>
            </div>

            <div class="flex-around" @click="form.sex = 0">
              <img src="../assets/public/check1.png" v-if="form.sex == 0" />
              <img src="../assets/public/check2.png" v-if="form.sex == 1" />
              <p>女</p>
            </div>
          </div>
          <div class="inp">
            <input
              type="text"
              placeholder="请输入手机号"
              :maxlength="11"
              v-model="form.phone"/>
            <span class="toyzm" @click="toyzm">{{ time }}</span>
          </div>

          <div class="inp">
            <input
              type="text"
              placeholder="请输入验证码"
              v-model="form.phone_code"/>
           
          </div>
          
          <div class="but" @click="upmsg">
            提交
          </div>
        </div>
        
      </div>
	</div>
  		<myfooter></myfooter>
		<myright></myright>
</div>
</template>

<script>
import { getyzmAPI, registerAPI,bindUserInfoAPI} from "../api/login";
import yzm from "../components/yzm";
import myheader from '../components/header.vue'
import myfooter from '../components/footer.vue'
import myright from '../components/right.vue'
export default {
components: {
    yzm,myheader, myfooter, myright
  },
  data () {
    return {
		view:{},
        form:{
            sex:1,
            phone:"",
            account:"",
            phone_code:""
        },
        password:"",
       time: "发送验证码",
        read: false,
	  
    }
  },
  created(){
	  
  },
  methods: {
    upmsg() {
      
       this.form.uid=JSON.parse(localStorage.getItem("wxInfo")).uid
       console.log(JSON.parse(localStorage.getItem("wxInfo")).uid)
          bindUserInfoAPI(this.form).then(res => {
            localStorage.setItem("token", res.token);
          this.$message({
              message: "绑定成功",
              type: "success"
            });
            
            this.$router.push({ path: '/user/my',query:{login:"success"}})
            
            
            
          });
       
     
    },
	toyzm() {
      if (this.form.phone == "") {
        this.$message({
          message: "请输入手机号",
          type: "error"
        });
      } else {
        if (this.time == "发送验证码") {
          var that = this;
          that.time = 60;
          if (that.time > 0) {
            var interval = setInterval(function() {
              that.time--;
              if (that.time == 0) {
                clearInterval(interval);
                that.time = "发送验证码";
              }
            }, 1000);
          }
          getyzmAPI({ phone: this.form.phone }).then(res => {
            this.$message({
              message: "验证码短信已发送",
              type: "success"
            });
          });
        } else {
          this.$message({
            message: "请耐心等待",
            type: "warning"
          });
        }
      }
    },
    
  }

}
</script>

<style lang="scss" scoped="scoped">
.city{

	padding: 30px 0;
	background-color: #f9f9f9;
}
    .login {
        background-color: #ffffff;
        width: 430px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        .form {
          width: 420px;
          padding: 35px 50px;
          .title {
            h3 {
              font-size: 20px;
              font-weight: 500;
              color: #333333;
              line-height: 28px;
            }
            p {
              font-size: 14px;

              color: #ff7c00;
              line-height: 20px;
            }
          }
          .inp {
            margin-top: 15px;
            display: flex;
            justify-content: space-between;
            position: relative;

            input {
              width: 321px;
              height: 40px;
              background: #f6f7f9;
              border-radius: 4px;
              text-indent: 15px;
            }
            .yzm {
              width: 170px;
            }
            .toyzm {
              color: #ff7c00;
              position: absolute;
              right: 20px;
              top: 0;
              line-height: 40px;
              cursor: pointer;
            }
            .pass {
              width: 150px;
            }
          }
          .sex {
            display: flex;
            margin-top: 15px;
            div {
              margin-right: 20px;
              img {
                margin-right: 10px;
              }
            }
          }
          .tip {
            display: flex;
            align-items: center;
            font-size: 13px;
            margin-top: 10px;
            color: #999999;
            line-height: 20px;
            p {
              color: #999999;
            }
            img {
              width: 14px;
              height: 14px;
              margin-right: 8px;
            }
            span {
              color: #ff7c00;
              font-size: 13px;
            }
            a{
              color: #ff7c00;
            }
          }
          .but {
            width: 321px;
            height: 45px;
            opacity: 1;
            background: #ff7c00;
            border-radius: 4px;
            text-align: center;
            margin-top: 20px;
            line-height: 45px;
            font-size: 18px;
            font-weight: 500;
            text-align: center;
            color: #ffffff;
            cursor: pointer;
          }
        }
        .wxewm{
           padding-top: 20px;
          width: 100%;
          display: flex;
          justify-content: center;
          // align-items: center;
          flex-wrap: wrap;
          h2{
            width: 100%;
            text-align: center;
           
          }

        }
      }
</style>
